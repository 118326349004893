import SocialAuthButtons from 'shared/components/auth/SocialAuthButtons'
import Button from 'shared/components/button/Button'
import WrongReferralCodeModal from 'views/authentication/components/WrongReferralCodeModal'
import InputErrorText from 'shared/components/input/InputErrorText'
import { PATTERN } from 'configs/validation'
import GCaptchaProvider, { useGCaptchaVerify } from 'contexts/GCaptchaProvider'
import useAuth from 'hooks/authentication/useAuth'
import Link from 'next/link'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'
import { SignUpAsRole } from 'types/App'
import { UserProfile } from 'types/V3/User'
import { useBoolean, useEffectOnce } from 'usehooks-ts'
import useAuthType from 'views/authentication/hooks/useAuthType'
import {
  authenticateWithApple,
  authenticateWithGoogle,
  authenticateWithTwitter,
} from 'views/authentication/services/authenticateWithProvider'
import authenticateWithRedirectResult from 'views/authentication/services/authenticateWithRedirectResult'
import loginUser from 'views/authentication/services/loginUser'
import signUpWithEmailAndPassword from 'views/authentication/services/signUpWithEmailAndPassword'
import swapCohartToken from 'views/authentication/services/swapCohartToken'
import logInWithEmailAndPassword from '../services/logInWithEmailAndPassword'
import debounce from 'lodash.debounce'
import StyledAuthInput from './StyledAuthInput'
import StyledPasswordInput from './StyledPasswordInput'
import TextLabel from 'shared/components/text/TextLabel'
import { AuthError, AuthErrorCodes } from 'firebase/auth'
import AttentionIcon from 'shared/icons/AttentionIcon'
import { AuthType } from 'types/Auth'
import { useRouter } from 'next/router'
import { WEB_BASE_URL } from 'configs'
import checkExistedUser from 'shared/services/checkExistedUser'
import { sendHubspotSignUpFormAPI, setUpAmplitudeTrackingForSignUp } from '../util'
import CohartLogo from './Logo'
// import EmailVerificationView from './EmailVerificationView'

type SignUpFormInputs = {
  firstName: string
  email: string
  password: string
  referralCode?: string
  useReferralCode?: boolean
}

type NormalSignUpProps = {
  isInvalidInvitationToken?: boolean
  asRole?: SignUpAsRole
  mode: 'page' | 'modal'
  onSuccess?: () => void
  marketingMessage?: ReactNode
  customizedSignUpTitle?: ReactNode
  event?: string
  onLogoClick?: () => void
}

const NormalSignUp = (props: NormalSignUpProps) => {
  const {
    isInvalidInvitationToken,
    asRole,
    mode,
    onSuccess,
    marketingMessage,
    customizedSignUpTitle,
    event,
    onLogoClick,
  } = props
  const { value: isPasswordVisible, toggle: togglePasswordVisible } = useBoolean(false)
  const { value: isRefferalCodeInputVisible, setTrue: setRefferalCodeInputVisible } = useBoolean(false)
  const { isCaptchaReady, handleReCaptchaVerify } = useGCaptchaVerify()
  const router = useRouter()
  const defaultReferralCode = router.query.referralCode as string
  const defaultEmail = router.query.email as string
  const redirectUrl = router.query.redirectUrl as string

  // TODO: comment this out since the business team requested to remove the OTP verification step, but we may re-enable this in the future
  // use this to check if we should show the OTP verification screen
  // const [step, setStep] = useState<'sign-up' | 'verify-email'>('sign-up')
  // const [signUpEmail, setSignUpEmail] = useState<string>()

  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<SignUpFormInputs>({
    defaultValues: {
      referralCode: defaultReferralCode || '',
      useReferralCode: Boolean(defaultReferralCode),
      email: defaultEmail || '',
    },
  })

  const email = watch('email')
  const firstName = watch('firstName')
  const password = watch('password')
  const enableSubmitButton = Boolean(email) && Boolean(firstName) && Boolean(password)

  const { closeAuthModal, setSignUpAsRole } = useAuth()

  const { value: isReferralModalVisible, setTrue: showReferralModal, setFalse: closeReferralModal } = useBoolean(false)

  const [authType, setAuthType] = useAuthType()
  const [isRequesting, setIsRequesting] = useState(false)
  const [signUpError, setSignUpError] = useState<string | null>(null)
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' })

  const handleAuthenticateError = useCallback((e: unknown) => {
    let errorMessage = 'Cannot sign up user'
    if (typeof e === 'string') {
      errorMessage = e
    }

    const code = (e as AuthError).code
    if (code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
      errorMessage = 'User closed the authentication popup'
    }

    setSignUpError(errorMessage)
    console.error('Sign up error:', e)
  }, [])

  const handleAuthenticateSuccess = useCallback(
    async (user: UserProfile, signUpMethod: AuthType) => {
      setUpAmplitudeTrackingForSignUp({
        userId: user.id,
        signUpMethod,
        asRole,
        email: user.email,
        firstName: user.firstName,
        createdAt: user.createdAt,
      })

      if (event === 'singapore-gallery-month') {
        trackAmplitudeCustomEvent('user_signed_up_for_singapore_gallery_month', {
          sign_up_date: new Date().toISOString(),
          user_role: asRole || 'henry',
        })
      }

      let destination = null
      if (redirectUrl) {
        destination = redirectUrl
      } else if (asRole) {
        destination = '/homepage'
      }

      setSignUpAsRole(undefined)

      if (onSuccess) {
        return onSuccess()
      }

      if (mode === 'modal') {
        if (destination) await router.push(destination || '/homepage')
        closeAuthModal()
        return
      }

      // if user sign up using the sign-up page, redirect to homepage if there's no destination
      await router.push(destination || '/homepage')
    },
    [asRole, event, redirectUrl, setSignUpAsRole, onSuccess, mode, router, closeAuthModal],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitSignUpForm = useCallback(
    debounce(async (data: SignUpFormInputs) => {
      if (isRequesting) return // auth is in progress

      await handleReCaptchaVerify('signup_user')
      setAuthType('password')
      setIsRequesting(true)
      // if user the referral code checkbox is not checked, dont send the referral code even if it's not empty
      const referralCode = data.useReferralCode ? data.referralCode : undefined
      try {
        // Add this check, since we don't want to send the Hubspot sign up FormAPI if email is existed,
        // and the check is happened in /sign-up-new
        const existedUser = await checkExistedUser(data.email)
        if (existedUser) {
          setError('email', { message: 'Email address already in use' })
          return
        }

        // send the Hubspot sign up Form API, for tracking purpose
        await sendHubspotSignUpFormAPI(data.firstName, data.email, `https://${WEB_BASE_URL}${router.asPath}`)

        // // sign up user on the backend
        await signUpWithEmailAndPassword({
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          referralCode,
          asRole,
        })

        // sign up successful, log in the user
        const userCredential = await logInWithEmailAndPassword(data.email, data.password)

        // swap cohart token (stored in cookie) by firebase credential
        const { user } = await swapCohartToken(userCredential)

        // mark user as logged in
        await loginUser(user)

        // go to verify email step
        // setStep('verify-email')
        // setSignUpEmail(data.email)
        // return // the rest of the process will be handled by the vefiry email step

        // success callback
        await handleAuthenticateSuccess(user, 'password')
      } catch (error) {
        const errorMessage = typeof error === 'string' ? error : 'Cannot sign up user'
        const isReferralCodeError = errorMessage.includes('referral') // workaround to check if the error is about referral code
        setError(isReferralCodeError ? 'referralCode' : 'email', { message: errorMessage })
        if (isReferralCodeError) {
          // show the referral modal, ask if user wants to continue without referral code
          showReferralModal()
        }
      } finally {
        setIsRequesting(false)
      }
    }, 1000),
    [isRequesting, handleReCaptchaVerify, asRole],
  )

  const signUpWithGoogle = async () => {
    if (isRequesting) return // auth is in progress

    setAuthType('google')
    setIsRequesting(true)

    try {
      const userCredential = await authenticateWithGoogle(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback

      // send the Hubspot sign up Form API, for tracking purpose
      await sendHubspotSignUpFormAPI(
        userCredential.user.displayName as string,
        userCredential.user.email as string,
        `https://${WEB_BASE_URL}${router.asPath}`,
      )

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential, { role: asRole })

      // mark user as logged in
      await loginUser(user)

      // success callback
      await handleAuthenticateSuccess(user, 'google')
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const signUpWithApple = async () => {
    if (isRequesting) return // auth is in progress

    setAuthType('apple')
    setIsRequesting(true)
    try {
      const userCredential = await authenticateWithApple(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback

      // send the Hubspot sign up Form API, for tracking purpose
      await sendHubspotSignUpFormAPI(
        userCredential.user.displayName as string,
        userCredential.user.email as string,
        `https://${WEB_BASE_URL}${router.asPath}`,
      )

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential, { role: asRole })
      // mark user as logged in
      await loginUser(user)

      // success callback
      await handleAuthenticateSuccess(user, 'apple')
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const signUpWithTwitter = async () => {
    if (isRequesting) return // auth is in progress

    setAuthType('twitter')
    setIsRequesting(true)
    try {
      const userCredential = await authenticateWithTwitter(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential, { role: asRole })

      // mark user as logged in
      await loginUser(user)

      // success callback
      await handleAuthenticateSuccess(user, 'twitter')
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const editReferralCode = () => {
    closeReferralModal()
    setRefferalCodeInputVisible()
    setTimeout(() => setFocus('referralCode'), 300)
  }

  const proceedWithoutReferralCode = async () => {
    clearErrors('referralCode')
    setValue('referralCode', '')
    closeReferralModal()
    const data = getValues()
    await submitSignUpForm(data)
  }

  const { showAuthModal, setAuthMode } = useAuth()

  const showSignInModal = useCallback(() => {
    setAuthMode('signin')
    showAuthModal()
  }, [setAuthMode, showAuthModal])

  // handle authentication with the redirect result
  useEffect(() => {
    if (isRequesting) return // auth is in progress

    const signUp = async () => {
      setIsRequesting(true)
      try {
        const userCredential = await authenticateWithRedirectResult()
        if (!userCredential) return // no redirect result, do nothing

        // swap cohart token (stored in cookie) by firebase credential
        const { user } = await swapCohartToken(userCredential, { role: asRole })

        // mark user as logged in
        await loginUser(user)

        // success callback
        await handleAuthenticateSuccess(user, 'password')
      } catch (e) {
        handleAuthenticateError(e)
      } finally {
        setIsRequesting(false)
      }
    }

    signUp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffectOnce(() => {
    trackAmplitudeCustomEvent('user_sign_up_started')
  })

  // verify otp step
  // if (step === 'verify-email' && signUpEmail) {
  //   return <EmailVerificationView email={signUpEmail} asRole={asRole} mode={mode} />
  // }

  const renderMessage = useMemo(() => {
    if (signUpError)
      return (
        <div className="flex items-center gap-1 text-xs normal-case">
          <div className="w-3">
            <AttentionIcon color="#FF4337" className="!size-3" />
          </div>
          <span className="text-[#FF4337]">&nbsp;{signUpError}</span>
        </div>
      )
    return null
  }, [signUpError])

  return (
    <>
      <div className="box-border h-full w-[80vw] space-y-3 font-inter md:w-[450px] lg:space-y-4 xl:space-y-6 2xl:space-y-8">
        {/* title */}
        <div className="text-center font-medium text-[#191414]">
          {isInvalidInvitationToken && (
            <p className="py-2 font-inter text-[16px]">Oops! Seems like the invitation link has expired or invalid</p>
          )}

          <h2 className="font-monument-grotes leading-none">
            {!isInvalidInvitationToken && (
              <span className="text-3xl lg:text-[48px]">{customizedSignUpTitle || 'Sign up'}</span>
            )}
            {isInvalidInvitationToken && <span className="text-[30px]">Sign up to showcase more of your work</span>}
          </h2>
        </div>
        {/* marketing message */}
        {!!marketingMessage && <p className="text-center text-[#191414] lg:text-xl">{marketingMessage}</p>}
        {/* sign up with social account buttons */}
        <SocialAuthButtons
          onGoogleButtonClick={signUpWithGoogle}
          onTwitterButtonClick={signUpWithTwitter}
          onAppleButtonClick={signUpWithApple}
          isGoogleButtonLoading={isRequesting && authType === 'google'}
          isTwitterButtonLoading={isRequesting && authType === 'twitter'}
          isAppleButtonLoading={isRequesting && authType === 'apple'}
        />

        <div className="flex w-full items-center opacity-50">
          <div className="h-[0.5px] grow bg-[#191414]" />
          <div className="mx-5 text-[12px] font-semibold uppercase text-[#191414]">Or sign up with</div>
          <div className="h-[0.5px] grow bg-[#191414]" />
        </div>

        {/* sign-up form */}
        <form onSubmit={handleSubmit(submitSignUpForm)} className="space-y-6">
          <div className="w-full space-y-3">
            {/* First Name Field */}
            <TextLabel label={'First name'} asterisk={true} size={'default'} as="label">
              <div className="h-2" />
              <StyledAuthInput
                placeholder="First name"
                {...register('firstName', {
                  required: {
                    value: true,
                    message: 'First name is required',
                  },
                  maxLength: { value: 30, message: 'First name must be less than 30 characters' },
                  validate: {
                    nonSpaceValue: (value) => !!value.trim() || 'First name is required',
                  },
                })}
              />

              <InputErrorText message={errors.firstName?.message} />
            </TextLabel>

            {/* Email Field */}
            <TextLabel label={'Email Address'} asterisk={true} size={'default'} as="label">
              <div className="h-2" />
              <StyledAuthInput
                placeholder="Email address"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  maxLength: { value: 254, message: 'Email must be less than 254 characters' },
                  pattern: {
                    value: PATTERN.email,
                    message: 'Please enter a valid email',
                  },
                })}
              />

              <InputErrorText message={errors.email?.message} />
            </TextLabel>

            {/* Password Field */}
            <TextLabel label={'Password'} asterisk={true} size={'default'} as="label">
              <div className="h-2" />
              <StyledPasswordInput
                isPasswordVisible={isPasswordVisible}
                togglePasswordVisible={togglePasswordVisible}
                placeholder="Password"
                {...register('password', {
                  required: { value: true, message: 'Password is required' },
                  minLength: { value: 6, message: 'Password is too short! Minimum 6 characters' },
                  pattern: {
                    value: PATTERN.password,
                    message: "Your password can't start or end with a blank space",
                  },
                })}
              />
              <InputErrorText message={errors.password?.message} />
            </TextLabel>
          </div>

          {/* Referral Code */}
          {isRefferalCodeInputVisible && (
            <TextLabel label={'Referral Code'} size={'default'} as="label">
              <div className="h-2" />
              <StyledAuthInput placeholder="Enter referral code" {...register('referralCode')} />

              <InputErrorText message={errors.referralCode?.message} />
            </TextLabel>
          )}

          {/* Referral Code Checkbox */}
          {renderMessage}

          <Button
            type="submit"
            name="Sign Up"
            wrapperClass="w-full rounded-[32px] !p-3 lg:!p-4 border border-black/10 bg-primary"
            contentClass="!text-sm lg:!text-base !font-semibold text-white !uppercase"
            loadingClassName="!border-b-white !border-r-white !border-t-white"
            disabled={!isCaptchaReady || isRequesting || !enableSubmitButton}
            loading={isRequesting && authType === 'password'}
          />
        </form>
        <div className="space-y-2 lg:space-y-3">
          {/* switch to sign-in option */}
          <div className="flex w-full justify-center text-sm">
            <span>
              Already have an account?{' '}
              {mode === 'page' && (
                <Link
                  passHref
                  href={redirectUrl ? `/login?redirectUrl=${encodeURIComponent(redirectUrl)}` : '/login'}
                  className="font-semibold text-primary hover:underline"
                >
                  Sign in
                </Link>
              )}
              {mode === 'modal' && (
                <a className="font-semibold text-primary hover:underline" role="button" onClick={showSignInModal}>
                  Sign in
                </a>
              )}
            </span>
          </div>

          {/* term and condition */}
          <div className="w-full text-center text-xs text-[#898788]">
            By signing up, you agree to our&nbsp;
            <a className="font-medium text-primary hover:underline" href="/terms-of-service" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>

        {/* logo */}
        <CohartLogo onClick={onLogoClick} />
      </div>

      {isReferralModalVisible && (
        <WrongReferralCodeModal onReEdit={editReferralCode} onProceedWithoutReferralCode={proceedWithoutReferralCode} />
      )}
    </>
  )
}

const NormalSignUpWithGCaptcha = (props: NormalSignUpProps) => {
  return (
    <GCaptchaProvider>
      <NormalSignUp {...props} />
    </GCaptchaProvider>
  )
}

NormalSignUpWithGCaptcha.displayName = 'NormalSignUp'
export default NormalSignUpWithGCaptcha
